import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import FourOFourPage from "../components/FourOFour/FourOFourPage";

const NotFoundPage = () => {
  return (
    <Root>
      <SEO title="404: Not found" />
      <FourOFourPage />
    </Root>
  );
};

export default NotFoundPage;
